import { yupResolver } from '@hookform/resolvers/yup';
import ArrowSvg from 'assets/images/_infoGraphics/arrow.svg';
import { Checkbox } from 'components/_inputs/Checkbox/Checkbox';
import InputPhone from 'components/_inputs/PhoneInput/InputPhone';
import { Textarea } from 'components/_inputs/Textarea/Textarea';
import { TextInput } from 'components/_inputs/TextInput/TextInput';
import { Section } from 'components/_layout/Section/Section';
import { notify } from 'components/_modals/ToastNotification/ToastNotification';
import * as Common from 'components/_universal/Common';
import * as emailjs from 'emailjs-com';
import { navigate } from 'gatsby';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { ROUTE_PRIVACY_POLICY, ROUTE_THANK_YOU } from 'shared/paths';
import { contactValidation } from 'shared/validation';
import { B2, B3 } from 'styles/Typography.styled';
import { ToastTypes } from 'types/CommonTypes';
import { addFromMagazineField } from 'utils/cookies';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { useFormValidation } from 'utils/hooks/useFormValidation';
import {
    CheckboxInsideWrapper,
    InputsWrapper,
    InsideFormWrapper,
    StyledLink,
    SubmitButton,
} from './ContactForm.styled';

export const ContactForm = () => {
    const isXs = useBreakpoint('xs');
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setError,
    } = useForm<FieldValues>({
        mode: 'all',
        resolver: yupResolver(contactValidation),
    });
    const form = watch();
    const {
        country,
        ipAddress,
        validateSubmit,
        parsePhone,
        setInputDialCountry,
    } = useFormValidation(form);

    const onValidSubmit = () => {
        emailjs
            .send(
                'default_service',
                'get_an_estimate',
                {
                    name: form.name,
                    email: form.email,
                    company: form.company,
                    phone: parsePhone(),
                    description: form.description,
                    country: country,
                    ip: ipAddress,
                    cb_softwareDevelopment: form.cb_softwareDevelopment,
                    cb_ai: form.cb_ai,
                    cb_other: form.cb_other,
                    cb_outsourcing: form.cb_outsourcing,
                    cb_hardwareEngineering: form.cb_hardwareEngineering,
                    cb_nda: form.cb_nda,
                    ...addFromMagazineField(),
                },
                'user_AFpd8OGklEw6C76b5ygNw',
            )
            .then(
                (result) => {
                    reset({
                        email: '',
                        name: '',
                        cb_privacyPolicy: undefined,
                        cb_nda: undefined,
                    });
                    navigate(ROUTE_THANK_YOU);
                },
                (error) => {
                    console.log('Error:: ', error);
                    return notify(error.text)[ToastTypes.ERROR]();
                },
            );
    };

    return (
        <Section title="Let's discuss!" isFirst withBreadCrumbs>
            <B3 mb={8} mt={4}>
                Embark on a tech adventure! Complete the form, and our experts
                will craft customized solutions
                {!isXs && <br />} for your business growth.
            </B3>
            <div>
                <Common.Div flex="column">
                    <Common.Div flex="row" gap="28px" w="100%">
                        <InsideFormWrapper>
                            <InputsWrapper
                                isError={!!errors.name || !!errors.email}
                            >
                                <TextInput
                                    label="Your name*"
                                    control={control}
                                    errors={errors.name}
                                    name="name"
                                    type={'text'}
                                />
                                <TextInput
                                    label="Email*"
                                    control={control}
                                    errors={errors.email}
                                    name="email"
                                    type={'email'}
                                />
                            </InputsWrapper>
                            <InputsWrapper
                                isError={!!errors.company || !!errors.phone}
                            >
                                <TextInput
                                    label="Company name*"
                                    control={control}
                                    errors={errors.company}
                                    name="company"
                                    type={'text'}
                                />
                                <InputPhone
                                    label="Phone number*"
                                    name="phone"
                                    control={control}
                                    errors={errors.phone}
                                    setInputDialCountry={setInputDialCountry}
                                />
                            </InputsWrapper>
                            <InputsWrapper isError={!!errors.description}>
                                <Textarea
                                    label="What would you like to do?*"
                                    control={control}
                                    errors={errors.description}
                                    name="description"
                                />
                            </InputsWrapper>
                            <B2 mb={4}>What is the scope of your project?</B2>
                            <Common.Div
                                flex={isXs ? 'column' : 'row'}
                                flexWrap="wrap"
                                gap="10px"
                                mb={isXs ? 1.25 : 2}
                            >
                                <CheckboxInsideWrapper>
                                    <Checkbox
                                        control={control}
                                        isError={
                                            !!errors.cb_softwareDevelopment
                                        }
                                        name="cb_softwareDevelopment"
                                    />
                                    <B2>Software development</B2>
                                </CheckboxInsideWrapper>
                                <CheckboxInsideWrapper>
                                    <Checkbox
                                        control={control}
                                        isError={!!errors.cb_ai}
                                        name="cb_ai"
                                    />
                                    <B2>Artificial intelligence</B2>
                                </CheckboxInsideWrapper>
                                <CheckboxInsideWrapper>
                                    <Checkbox
                                        control={control}
                                        isError={!!errors.cb_outsourcing}
                                        name="cb_outsourcing"
                                    />
                                    <B2>Outsourcing</B2>
                                </CheckboxInsideWrapper>
                            </Common.Div>
                            <Common.Div
                                flex={isXs ? 'column' : 'row'}
                                flexWrap="wrap"
                                gap="10px"
                                mb={8}
                            >
                                <CheckboxInsideWrapper minW="212px">
                                    <Checkbox
                                        control={control}
                                        isError={
                                            !!errors.cb_cb_hardwareEngineering
                                        }
                                        name="cb_hardwareEngineering"
                                    />
                                    <B2>Hardware engineering</B2>
                                </CheckboxInsideWrapper>
                                <CheckboxInsideWrapper>
                                    <Checkbox
                                        control={control}
                                        isError={!!errors.cb_other}
                                        name="cb_other"
                                    />
                                    <B2>other</B2>
                                </CheckboxInsideWrapper>
                            </Common.Div>
                            <Common.Div
                                flex="row"
                                flexWrap="wrap"
                                gap="10px"
                                mb={2}
                            >
                                <CheckboxInsideWrapper>
                                    <Checkbox
                                        control={control}
                                        isError={!!errors.cb_nda}
                                        name="cb_nda"
                                    />
                                    <B2>I need to sign the NDA first</B2>
                                </CheckboxInsideWrapper>
                            </Common.Div>
                            <CheckboxInsideWrapper>
                                <Checkbox
                                    control={control}
                                    isError={!!errors.cb_privacyPolicy}
                                    name="cb_privacyPolicy"
                                />
                                <B2>
                                    I agree with{' '}
                                    <StyledLink
                                        href={ROUTE_PRIVACY_POLICY}
                                        target="_blank"
                                    >
                                        Privacy Policy
                                    </StyledLink>
                                    *
                                </B2>
                            </CheckboxInsideWrapper>
                            <SubmitButton
                                mt={12}
                                onClick={handleSubmit(
                                    () =>
                                        validateSubmit(onValidSubmit, setError),
                                    (e) => console.log(e),
                                )}
                            >
                                <B2>send</B2>
                                <Common.Svg src={ArrowSvg} />
                            </SubmitButton>
                        </InsideFormWrapper>
                    </Common.Div>
                </Common.Div>
            </div>
        </Section>
    );
};
