import { CommonMargin } from 'components/_layout/CommonMargin';
import SEO from 'components/_layout/Seo';
import React from 'react';
import { ContactForm } from 'sections/Contact/ContactForm/ContactForm';

export default () => {
    return (
        <CommonMargin>
            <SEO
                title="Contact"
                meta={[
                    {
                        name: 'keywords',
                        content:
                            'embedded ai, react native app development services, react native debugger, latest react version, react-app-rewired, react and react native, react software, angular software, react native reanimated, react native gesture handler, react native ide, react native slider, react native bottom sheet, react-native-web, react animation, react-spring, react-native-screens, react switch, react accordion, react debugger, react modal, react-native-modal, react setstate callback, expo development build, react native for web, react-native-config, react native share, react native flatlist, react native image, jest react, jest npm',
                    },
                ]}
                description={
                    'Transform your applications with our React Native app development services, leveraging the power of embedded AI and the latest React version. Our expertise includes tools like React Native debugger, react-app-rewired, and the React Native IDE for seamless development. We utilize advanced features such as react-native-reanimated, gesture handler, and user interface components like react-native-slider and bottom sheet. With solutions spanning react-native-web, react animation, and react-spring, we provide robust software solutions, ensuring your applications are interactive and responsive. Explore how our comprehensive React software development can elevate your projects today!'
                }
            />
            <ContactForm />
        </CommonMargin>
    );
};
